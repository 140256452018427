import s from "./Results.module.scss";
import { useState, useEffect } from "react";

export default function Results() {
  const [results, setResults] = useState([]);

  useEffect((e) => {
    fetch("./api/get")
      .then((res) => res.json())
      .then((res) => setResults(res));
  }, []);
  return (
    <div className={s.results}>
      <h2>Спасибо за голосование!</h2>
      <h4>Результаты:</h4>
      {results.map((e) => (
        <p key={e.id}>
          {e.vote}: <span>{e.count}</span>
        </p>
      ))}
    </div>
  );
}
