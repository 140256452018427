import s from "./Radio.module.scss";

export default function Radio({ name }) {
  return (
    <p className={s.radio}>
      <input type="checkbox" id={name} name={name} value={name} />
      <label htmlFor={name}>{name}</label>
    </p>
  );
}
