import { useState } from "react";
import Form from "../Form/Form";
import Results from "../Results/Results";
import "./App.scss";

const App = () => {
  const [voited, setVoit] = useState(localStorage.getItem("voited"));

  return (
    <div className="wrapper">
      {!voited ? <Form setVoit={setVoit} /> : <Results />}
      {/* <Results /> */}
    </div>
  );
};

export default App;
