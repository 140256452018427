import Radio from "../Radio/Radio";

import s from "./Form.module.scss";

export default function Form({ setVoit }) {
  const submitForm = (e) => {
    e.preventDefault();
    const collection = e.target.elements;
    const data = [];
    for (let i = 0; i < collection.length; i++) {
      if (collection[i].name) {
        const elem = collection[i];
        const obj = {};
        obj["name"] = elem.name;
        obj["checked"] = elem.checked;
        data.push(obj);
      }
    }

    fetch("./api/post", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });

    setVoit(true);
    localStorage.setItem("voited", "true");
  };

  return (
    <form className={s.form} onSubmit={(e) => submitForm(e)}>
      <h3>Новые темы</h3>
      <h4>(можно выбирать несколько)</h4>
      <Radio name="NodeJs" />
      <Radio name="Sequelize" />
      <Radio name="Reddis" />
      <button>Submit</button>
    </form>
  );
}
